import React from 'react'
import { translate } from 'react-i18next'
import SocialNetwork from 'components/SocialNetwork'

import { grayscale } from 'src/styles/colors'

type simpleFooterProps = {
  t: (value: string) => string;
}

const SimpleFooter = ({ t }: simpleFooterProps) => (
  <footer className='simple-menu--footer pb-5'>
    <div className='container'>
      <div className='row'>
        <div className='col-12 text-center'>
          <p className='fs-17 mt-1 mb-1 fw-700 text-gray--900'>{t('social_helper')}</p>
          <SocialNetwork showTitle={false} color={grayscale[500]} />
        </div>
      </div>
    </div>
  </footer>
)

export default translate('404')(SimpleFooter)
