import React from 'react'
import { translate, InjectedTranslateProps } from 'react-i18next'
import Link from 'components/GatsbyLinkWrapper/index'

import Layout from 'src/layouts/BaseLayout'
import SimpleFooter from 'components/Layout/Footer/SimpleFooter'

import image404 from 'assets/images/temps/404.png'

let language: boolean | string | RegExpMatchArray | null = typeof window !== 'undefined' && window.location.pathname.match(/(\/)(\w{2})(\/)/)
language = language ? language[0].replace(/\//g, '') : 'pt-BR'

const NotFound = ({ t }: InjectedTranslateProps) => {
  const slugLang = language === 'en' ? '/en/notFound/' : '/notFound/'

  const contentMetas = {
    slug: slugLang,
    title: t('title'),
    hideFooter: true,
    noIndexOption: true,
    hideOpenAccountBottomForm: true,
  }

  return (
    <Layout pageContext={contentMetas}>
      <section className='pt-4 py-lg-5 text-center text-lg-left'>
        <div className='container pt-xl-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='fs-30 fs-lg-42 text-grayscale--500 mb-md-5'>{t('page_not_found')}</h3>
            </div>
            <div className='col-sm-12 col-lg-5 col-xl-6 order-lg-2 pb-4 pb-lg-0 text-center mt-lg-n4 mt-xl-n5'>
              <img className='img-fluid' src={image404} alt='404 image' />
            </div>
            <div className='col-sm-12 col-lg-7 col-xl-6 order-lg-1'>
              <p className='fs-18 lh-22 fs-lg-20 text-grayscale--500'>{t('page_not_found_subtitle')}</p>
              <ul className='list-unstyled link-list--404 fw-400'>
                <li>
                  <Link to='/' title={t('home')}>{t('home')}</Link>
                </li>
                <li>
                  <Link to='/pra-voce/conta-digital/pessoa-fisica/' title={t('digital_account')}> {t('digital_account')}</Link>
                </li>
                <li>
                  <Link to='/emprestimos/' title={t('loan_leasing')}>{t('loan_leasing')}</Link>
                </li>
                <li>
                  <Link to='/pra-voce/financiamento-imobiliario/residencial/' title={t('real_state_financing')}>{t('real_state_financing')}</Link>
                </li>
                <li>
                  <Link to='/pra-voce/investimentos/' title={t('investment')}>{t('investment')}</Link>
                </li>
                <li>
                  <Link to='/pra-voce/seguros/' title={t('insurance')}>{t('insurance')}</Link>
                </li>
                <li>
                  <Link to='/cambio/' title={t('exchange')}>{t('exchange')}</Link>
                </li>
                <li>
                  <Link to='/pra-voce/consorcio/' title={t('leasing')}>{t('leasing')}</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <SimpleFooter />
    </Layout>
  )
}

export default translate('404')(NotFound)
